/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Input,
  Label,
  Textarea,
  Button,
} from 'theme-ui';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

const ContactPage = (props) => {
  const {
    siteUrl,
    gatsbyStorefrontConfig: { storeName },
  } = props.data.site.siteMetadata;
  const { hrefLang } = props.pageContext;
  return (
    <>
      <Helmet
        title={t({
          id: `Contact.Title`,
          message: `Contact us`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Contact.MetaDescription`,
            message: `Contact Kisscurls`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Contact.BreadcrumbsTitle`,
              message: `Contact us`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Contact.Header">Contact us</Trans>
        </Heading>
        <Text as="p" mt={[2, 3]}>
          <Trans id="Contact.Description">
            Please use this form to contact us if you have questions or need
            assistance choosing hair extensions.
          </Trans>
        </Text>

        <Text as="p">
          <Trans id="Contact.DescriptionPhotos">
            In case sending your photos please make sure that they are of good
            quality, made in natural lighting, and without image filters.
          </Trans>
        </Text>

        <Box sx={{ maxWidth: 600, mx: 'auto' }} mt={[3, 4]}>
          <form
            method="POST"
            action="https://formsubmit.co/faa836047349b4c22919bd05128524b8"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_next" value={`${siteUrl}/thank-you`} />
            <input type="text" name="_honey" sx={{ display: 'none' }} />
            <input type="hidden" name="_captcha" value="false" />
            <Label htmlFor="name">
              <Trans id="Contact.FormName">Your name</Trans>
            </Label>
            <Input type="name" name="name" mb={[2, 3]} />
            <Label htmlFor="email">
              <Trans id="Contact.FormEmail">E-mail to contact you back</Trans>
            </Label>
            <Input type="email" name="email" mb={[2, 3]} />
            <Label htmlFor="email">
              <Trans id="Contact.FormPhone">Your phone number</Trans>
            </Label>
            <Input type="phone" name="phone" mb={[2, 3]} />
            <Label htmlFor="message">
              <Trans id="Contact.FormMessage">Message</Trans>
            </Label>
            <Textarea name="message" mb={[2, 3]}></Textarea>
            <Label>
              <Trans id="Contact.FormAttachments">
                Attachments (for your photos and videos)
              </Trans>
            </Label>
            <Input
              type="file"
              name="attachment1"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment2"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment3"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />

            <Input
              type="file"
              name="attachment4"
              accept="image/png, image/jpeg, video/mp4"
              mb={[2, 3]}
            />
            <Button type="submit" mb={[2, 3]} px={[4, 5]} py={[2, 3]}>
              <Trans id="Contact.FormButton">Submit form</Trans>
            </Button>
          </form>
        </Box>

        <Heading as="h2" sx={{ fontSize: [3, 4] }}>
          <Trans id="Contact.EmailHeader">E-mail</Trans>
        </Heading>

        <Text as={Flex} sx={{ alignItems: 'center' }}>
          <Trans id="Contact.EmailDescription">
            To contact us by e-mail please use the following address:{' '}
          </Trans>
          {hrefLang === 'ru-BY' ? (
            <StaticImage
              src="../images/email-by.png"
              alt="E-mail"
              height={20}
            />
          ) : (
            <StaticImage
              src="../images/email.png"
              alt="E-mail"
              layout="fixed"
              height={20}
            />
          )}
        </Text>
      </Box>
    </>
  );
};

export const ContactPageeQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default ContactPage;
